import React from 'react';
import MapComponent from '../components/MapComponent';
import QuickStat from '../components/QuickStat';

const MapPage = () => {
  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <div>
        <QuickStat />
      </div>
      <div id="map-component" style={{ flex: 1, height: '100%' }}>
        <MapComponent />
      </div>
    </div>
  );
};

export default MapPage;