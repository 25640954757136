import React from 'react';

const TopPanel = () => {

    return (
        <nav id="top-panel" style={{ display: "flex", alignItems: "center", height: "100%" }} >
            <h6 style={{ flex: 1 }} className='px-3 mb-1 mt-1'>SUPERWAKE</h6>
        </nav >
    );
};

export default TopPanel;