import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import { WebSocketProvider } from './WebSocketContext';
import MapPage from './pages/MapPage'; // Page 1: Full Page Map
import NavigationPanel from './panels/NavigationPanel';
import TopPanel from './panels/TopPanel'; // Import TopPanel


function App() {
  return (
    <Router>
      <WebSocketProvider>

        <div className="app-container d-flex flex-column vh-100 bg-dark">
          <header className="border-bottom">
            <TopPanel />
          </header>

          <div className="flex-grow-1 d-flex bg-dark " >
            <NavigationPanel />
            <main className="flex-grow-1">
              <Routes>
                <Route path="/" element={<MapPage />} />
                {/* <Route path="/camera" element={<CameraPage />} />
                <Route path="/explore" element={<ExplorePage />} />
                <Route path="/debug" element={<DebugPage />} />
                <Route path="/plot" element={<PlotPage />} />
                <Route path="/power" element={<PowerPage />} />
                <Route path="/checklist" element={<ChecklistPage />} /> */}
              </Routes>
            </main>
          </div>
        </div>

      </WebSocketProvider>
    </Router >
  );
}

export default App;