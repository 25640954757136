import {
    faMap
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const renderTooltip = (props, text) => (
    <Tooltip id="button-tooltip" {...props} style={{ fontSize: '1em', ...props.style }}>
        {text}
    </Tooltip>
);

// https://fontawesome.com/search?o=r&m=free&f=classic
const navItems = [
    { path: '/', tooltip: 'Map', icon: faMap },
    // { path: '/tracker', tooltip: 'Tracker', icon: faSatelliteDish },
    // { path: '/power', tooltip: 'Power', icon: faBolt },
    // { path: '/checklist', tooltip: 'Checklist', icon: faListCheck },
    // { path: '/camera', tooltip: 'Camera', icon: faCamera },
    // { path: '/daa', tooltip: 'DAA', icon: faPlaneCircleExclamation },
    // { path: '/plan', tooltip: 'Plan', icon: faRoute },
    // { path: '/plot', tooltip: 'Plot', icon: faChartLine },
    // { path: '/explore', tooltip: 'Explore', icon: faMagnifyingGlass },
    // { path: '/debug', tooltip: 'Debug', icon: faBug },
];

const NavigationPanel = () => {
    return (
        <div className="navigation-panel">
            <ul className="nav nav-pills flex-column text-center">
                {navItems.map((item, index) => (
                    <li key={index} className="nav-item">
                        <OverlayTrigger
                            placement="right"
                            overlay={props => renderTooltip(props, item.tooltip)}
                        >
                            <NavLink to={item.path} className="nav-link py-2 px-2 border-bottom rounded-0">
                                <FontAwesomeIcon icon={item.icon} style={{ fontSize: '1.2em' }} />
                            </NavLink>
                        </OverlayTrigger>
                    </li>
                ))}
            </ul>
        </div >
    );
};

export default NavigationPanel;