import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { ACFT_COMP_ID, ACFT_SYS_ID, TRK_COMP_ID, TRK_SYS_ID } from '../Constants';
import { useGenericData, useMavlinkData } from '../WebSocketContext';


const CODY_LAT = 60.855009;
const CODY_LON = -135.519245;

const BRIAN_LAT = 60.777276
const BRIAN_LON = -136.246253

const AL_LAT = 60.90995
const AL_LON = -135.15014

const CARMACKS_LAT = 62.1142
const CARMACKS_LON = -136.1938

const HAINES_LAT = 60.7889
const HAINES_LON = -137.5397

// TODO: reduce expire time on redis. Retain data if no new data is received but show stale data flag

const QuickStat = () => {
    const ap_mode = useGenericData("vehicle:1:SYSTEM_STATUS", "mode");

    const airspeed = useMavlinkData(ACFT_SYS_ID, ACFT_COMP_ID, "VFR_HUD", "airspeed");
    const groundSpeed = useMavlinkData(ACFT_SYS_ID, ACFT_COMP_ID, "VFR_HUD", "groundspeed");
    const distance_to_wp = useMavlinkData(ACFT_SYS_ID, ACFT_COMP_ID, "NAV_CONTROLLER_OUTPUT", "wp_dist");

    const wind_direction_raw = useMavlinkData(ACFT_SYS_ID, ACFT_COMP_ID, "WIND", "direction") || 0;
    const wind_direction = (wind_direction_raw + 360) % 360;
    const wind_speed = useMavlinkData(ACFT_SYS_ID, ACFT_COMP_ID, "WIND", "speed") || 0;

    const altitude_msl_m = useMavlinkData(ACFT_SYS_ID, ACFT_COMP_ID, "VFR_HUD", "alt");
    const altitude_msl = altitude_msl_m !== null && altitude_msl_m !== undefined
        ? `${altitude_msl_m.toFixed(0)} m / ${(altitude_msl_m * 3.28084).toFixed(0)} ft`
        : 'N/A';

    const altitude_agl_m = useMavlinkData(ACFT_SYS_ID, ACFT_COMP_ID, "TERRAIN_REPORT", "current_height");
    const altitude_agl = altitude_agl_m !== null && altitude_agl_m !== undefined
        ? `${altitude_agl_m.toFixed(0)} m / ${(altitude_agl_m * 3.28084).toFixed(0)} ft`
        : 'N/A';

    const total_mppt_input = useGenericData("vehicle:1:MPPT_STATUS", "total_power")

    const main_battery_voltage = useGenericData("vehicle:1:BATTERY_STATUS:0", "voltages[0]", 1 / 1000.0)
    const main_battery_voltage_no_load = useGenericData("vehicle:1:MAIN_BATTERY", "voltage_0")

    const rh = useMavlinkData(ACFT_SYS_ID, ACFT_COMP_ID, "NAMED_VALUE_FLOAT_RH", "value", 1.0)
    const oat = useMavlinkData(ACFT_SYS_ID, ACFT_COMP_ID, "NAMED_VALUE_FLOAT_TEMP", "value", 1.0)
    const dew_point = useMavlinkData(ACFT_SYS_ID, ACFT_COMP_ID, "NAMED_VALUE_FLOAT_DEWP", "value", 1.0)

    const dew_point_spread = dew_point !== null && oat !== null ? oat - dew_point : null;

    // distance to base
    const acft_lat = useMavlinkData(ACFT_SYS_ID, ACFT_COMP_ID, "GLOBAL_POSITION_INT", "lat", 1 / 1e7)
    const acft_lon = useMavlinkData(ACFT_SYS_ID, ACFT_COMP_ID, "GLOBAL_POSITION_INT", "lon", 1 / 1e7)
    const trk_lat = useMavlinkData(TRK_SYS_ID, TRK_COMP_ID, "GLOBAL_POSITION_INT", "lat", 1 / 1e7)
    const trk_lon = useMavlinkData(TRK_SYS_ID, TRK_COMP_ID, "GLOBAL_POSITION_INT", "lon", 1 / 1e7)

    const toRadians = (degrees) => degrees * (Math.PI / 180);

    const calculateGreatCircleDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371e3; // Earth's radius in meters
        const phi1 = toRadians(lat1);
        const phi2 = toRadians(lat2);
        const dphi = toRadians(lat2 - lat1);
        const dlambda = toRadians(lon2 - lon1);

        const a = Math.sin(dphi / 2) * Math.sin(dphi / 2) +
            Math.cos(phi1) * Math.cos(phi2) *
            Math.sin(dlambda / 2) * Math.sin(dlambda / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c; // distance in meters
    };

    const calculateDirection = (lat1, lon1, lat2, lon2) => {
        const phi1 = toRadians(lat1);
        const phi2 = toRadians(lat2);
        const dlambda = toRadians(lon2 - lon1);

        const y = Math.sin(dlambda) * Math.cos(phi2);
        const x = Math.cos(phi1) * Math.sin(phi2) -
            Math.sin(phi1) * Math.cos(phi2) * Math.cos(dlambda);
        const brng = Math.atan2(y, x);

        return (brng * 180 / Math.PI + 360) % 360;
    }

    const metersToCody = acft_lat !== null && acft_lon !== null
        ? calculateGreatCircleDistance(CODY_LAT, CODY_LON, acft_lat, acft_lon).toFixed(0)
        : 'N/A';
    const metersToBrian = acft_lat !== null && acft_lon !== null
        ? calculateGreatCircleDistance(BRIAN_LAT, BRIAN_LON, acft_lat, acft_lon).toFixed(0)
        : 'N/A';
    const metersToCarmacks = acft_lat !== null && acft_lon !== null
        ? calculateGreatCircleDistance(CARMACKS_LAT, CARMACKS_LON, acft_lat, acft_lon).toFixed(0)
        : 'N/A';
    const metersToAl = acft_lat !== null && acft_lon !== null
        ? calculateGreatCircleDistance(AL_LAT, AL_LON, acft_lat, acft_lon).toFixed(0)
        : 'N/A';
    const metersToHaines = acft_lat !== null && acft_lon !== null
        ? calculateGreatCircleDistance(HAINES_LAT, HAINES_LON, acft_lat, acft_lon).toFixed(0)
        : 'N/A';
    const metersToGCS = acft_lat !== null && acft_lon !== null && trk_lat !== null && trk_lon !== null
        ? calculateGreatCircleDistance(trk_lat, trk_lon, acft_lat, acft_lon).toFixed(0)
        : 'N/A';

    const AIRSPEED_TARGET = 16.0;

    const calculateTimeToTarget = (windSpeed, windDirection, distance, targetDirection, airspeed) => {
        const headwindComponent = windSpeed * Math.cos(toRadians(windDirection) - toRadians(targetDirection));
        const groundSpeed = airspeed - headwindComponent;
        // console.log(`Ground Speed: ${groundSpeed}, headwindComponent: ${headwindComponent}`);
        return distance / groundSpeed;
    };

    const formatTimeToTarget = (seconds) => {
        if (isNaN(seconds)) {
            return 'XX:XX';
        }
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.round(seconds % 60);
        return `${minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
    };

    const formatDistance = (meters, lat1, lon1, lat2, lon2, wind_speed, wind_direction, airspeed) => {
        return meters !== null && meters !== undefined
            ? `${(meters / 1000.0).toFixed(1)} KM / ${(meters * 0.539957 / 1000.0).toFixed(1)} NM  / ${formatTimeToTarget(
                calculateTimeToTarget(wind_speed, wind_direction, meters, calculateDirection(lat1, lon1, lat2, lon2), airspeed)
            )}`
            : 'N/A';
    };

    const distanceToGCS = formatDistance(metersToGCS, acft_lat, acft_lon, AL_LAT, AL_LON, wind_speed, wind_direction, AIRSPEED_TARGET);
    const distanceToCody = formatDistance(metersToCody, acft_lat, acft_lon, CODY_LAT, CODY_LON, wind_speed, wind_direction, AIRSPEED_TARGET);
    const distanceToBrian = formatDistance(metersToBrian, acft_lat, acft_lon, BRIAN_LAT, BRIAN_LON, wind_speed, wind_direction, AIRSPEED_TARGET);
    const distanceToAl = formatDistance(metersToAl, acft_lat, acft_lon, AL_LAT, AL_LON, wind_speed, wind_direction, AIRSPEED_TARGET);
    const distanceToCarmacks = formatDistance(metersToCarmacks, acft_lat, acft_lon, CARMACKS_LAT, CARMACKS_LON, wind_speed, wind_direction, AIRSPEED_TARGET);
    const distanceToHaines = formatDistance(metersToHaines, acft_lat, acft_lon, HAINES_LAT, HAINES_LON, wind_speed, wind_direction, AIRSPEED_TARGET);

    const data = [
        {
            section: 'Distances',
            stats: [
                // { description: 'Wind', value: `FROM ${wind_direction.toFixed(0)}° @ ${wind_speed.toFixed(1)} m/s` },
                { description: 'GCS', value: distanceToGCS },
                { description: `Cody's`, value: distanceToCody },
                { description: `Brian's`, value: distanceToBrian },
                { description: `Al's`, value: distanceToAl },
                { description: `Carmacks`, value: distanceToCarmacks },
                { description: `Haines Jct.`, value: distanceToHaines },
            ],
        },
        {
            section: 'Aircraft',
            stats: [
                { description: 'Mode', value: ap_mode },
                { description: 'Airspeed', unit: 'm/s', value: airspeed, fixPoint: 1 },
                { description: 'Ground Speed', unit: 'm/s', value: groundSpeed, fixPoint: 1 },
                { description: 'Altitude (MSL)', unit: '', value: altitude_msl, fixPoint: 0 },
                { description: 'Altitude (AGL)', unit: '', value: altitude_agl, fixPoint: 0 },
                { description: 'Dist. to WP', unit: 'm', value: distance_to_wp, fixPoint: 0 },
            ],
        },
        {
            section: 'Environment',
            stats: [
                { description: 'Relative Humidity', unit: '%', value: rh, fixPoint: 1 },
                { description: 'OAT', unit: '°C', value: oat, fixPoint: 1 },
                { description: 'Dew Point', unit: '°C', value: dew_point, fixPoint: 1 },
                { description: 'D.P. Spread', unit: '°C', value: dew_point_spread, fixPoint: 1 },
            ],
        },
        {
            section: 'Power',
            stats: [
                { description: 'Main Battery', unit: 'V', value: main_battery_voltage, fixPoint: 2 },
                { description: 'Main Battery (No Load)', unit: 'V', value: main_battery_voltage_no_load, fixPoint: 2 },
                { description: 'Solar Input', unit: 'W', value: total_mppt_input, fixPoint: 0 },
            ],
        },
    ];

    const formatValue = (value, fixPoint) => {
        return value !== null && value !== undefined ? `${value.toFixed(fixPoint)}` : 'N/A';
    };

    return (
        <>
            <Card style={{ width: '300px' }} data-bs-theme="dark" className='rounded-0'>
                {data.map(({ section, stats }, sectionIndex) => (
                    <Card key={sectionIndex} className='bg-dark text-white rounded-0'>
                        <Card.Title className="widget-small-title mx-3 my-2">{section}</Card.Title>
                        <ListGroup variant="flush">
                            {stats.map(({ description, value, unit, fixPoint = 2 }, statIndex) => (
                                <ListGroup.Item key={statIndex}>
                                    {description}: {typeof value === 'number' ? formatValue(value, fixPoint) : value} {unit}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Card>
                ))}
            </Card >
        </>
    );
};

export default QuickStat;