import React from 'react';
import { ACFT_SYS_ID } from '../Constants';
import { useGenericData } from '../WebSocketContext';

const MissionStatus = () => {
    const missionReceived = useGenericData(`vehicle:${ACFT_SYS_ID}:MISSION_STATUS`, "received");
    const missionExpected = useGenericData(`vehicle:${ACFT_SYS_ID}:MISSION_STATUS`, "expected");
    const missionState = useGenericData(`vehicle:${ACFT_SYS_ID}:MISSION_STATUS`, "state");

    return (
        missionState === 'DOWNLOADING' && (
            <div style={{
                position: 'fixed',
                top: 10,
                right: 10,
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                color: 'white',
                padding: '5px 10px',
                borderRadius: '4px',
                fontSize: '14px',
                zIndex: 9999,
            }}>
                Downloading Mission: {missionReceived} / {missionExpected}
            </div>
        )
    );
};

export default MissionStatus;
