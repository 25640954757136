import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { ACFT_COMP_ID, ACFT_SYS_ID, TRK_COMP_ID, TRK_SYS_ID } from './Constants';

// TODO: handle visibility change of the tab and reconnect the websocket. reconnect attempt when the tab is visible, and stop when the tab is not visible
// TODO: dynamically adjust the subscription topics based on the current page or what data is requested to the context provider

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const [data, setData] = useState({});
  const socket = useRef(null);

  const topics = [
    `mavlink:${ACFT_SYS_ID}:${ACFT_COMP_ID}:NAV_CONTROLLER_OUTPUT`,
    `mavlink:${TRK_SYS_ID}:${TRK_COMP_ID}:NAV_CONTROLLER_OUTPUT`,
    `mavlink:${ACFT_SYS_ID}:${ACFT_COMP_ID}:GLOBAL_POSITION_INT`,
    `mavlink:${TRK_SYS_ID}:${TRK_COMP_ID}:GLOBAL_POSITION_INT`,
    `mavlink:${ACFT_SYS_ID}:${ACFT_COMP_ID}:GPS2_RAW`,
    `mavlink:${TRK_SYS_ID}:${TRK_COMP_ID}:GPS2_RAW`,
    `mavlink:${ACFT_SYS_ID}:${ACFT_COMP_ID}:GPS_RAW_INT`,
    `mavlink:${TRK_SYS_ID}:${TRK_COMP_ID}:GPS_RAW_INT`,
    `mavlink:${ACFT_SYS_ID}:${ACFT_COMP_ID}:VFR_HUD`,
    `mavlink:${ACFT_SYS_ID}:${ACFT_COMP_ID}:WIND`,
    `mavlink:${ACFT_SYS_ID}:${ACFT_COMP_ID}:TERRAIN_REPORT`,
    `mavlink:${ACFT_SYS_ID}:${ACFT_COMP_ID}:ATTITUDE`,
    `mavlink:${ACFT_SYS_ID}:${ACFT_COMP_ID}:AIRSPEED_AUTOCAL`,
    `mavlink:${ACFT_SYS_ID}:${ACFT_COMP_ID}:LOCAL_POSITION_NED`,
    `mavlink:${ACFT_SYS_ID}:${ACFT_COMP_ID}:NAMED_VALUE_FLOAT_AS2`,
    `mavlink:${ACFT_SYS_ID}:${ACFT_COMP_ID}:PID_TUNING`,
    `mavlink:${ACFT_SYS_ID}:${ACFT_COMP_ID}:SERVO_OUTPUT_RAW`,
    `mavlink:${ACFT_SYS_ID}:${ACFT_COMP_ID}:ESC_TELEMETRY_1_TO_4`,
    `mavlink:${ACFT_SYS_ID}:${ACFT_COMP_ID}:NAMED_VALUE_FLOAT_RH`,
    `mavlink:${ACFT_SYS_ID}:${ACFT_COMP_ID}:NAMED_VALUE_FLOAT_TEMP`,
    `mavlink:${ACFT_SYS_ID}:${ACFT_COMP_ID}:NAMED_VALUE_FLOAT_DEWP`,
    `payload:NEXTVISION:1:GROUND_CROSSING_REPORT`,
    `payload:NEXTVISION:1:LOS_REPORT`,
    `payload:NEXTVISION:1:SYSTEM_REPORT`,
    `vehicle:${ACFT_SYS_ID}:MPPT_STATUS`,
    `vehicle:${ACFT_SYS_ID}:BATTERY_STATUS:0`,
    `vehicle:${ACFT_SYS_ID}:BATTERY_STATUS:1`,
    `vehicle:${ACFT_SYS_ID}:BATTERY_STATUS:3`,
    `vehicle:${ACFT_SYS_ID}:MAIN_BATTERY`,
    `vehicle:${ACFT_SYS_ID}:SYSTEM_STATUS`,
    `vehicle:${ACFT_SYS_ID}:MISSION_STATUS`,
  ];

  useEffect(() => {
    const websocketURL = process.env.REACT_APP_WS_URL
    socket.current = new WebSocket(websocketURL);
    socket.current.onopen = () => {
      // Send a message to subscribe to topics when the connection is open
      const request = {
        event: 'subscribeReq',
        payload: {
          topics: topics,
        },
      };
      console.log('Sending WebSocket message:', request);
      socket.current.send(JSON.stringify(request));
      console.log('Message sent');
    };

    socket.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      // console.log('Received WebSocket message:', message);
      setData(message);
    };

    socket.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    return () => {
      socket.current.close();
    };
  }, []);

  const sendMessage = (message) => {
    if (socket.current && socket.current.readyState === WebSocket.OPEN) {
      socket.current.send(JSON.stringify(message));
    } else {
      console.error('WebSocket is not open. Unable to send message:', message);
    }
  };

  return (
    <WebSocketContext.Provider value={{ data, sendMessage }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);
export const useWebSocketData = (key) => {
  const ws = useWebSocket();
  return ws.data[key];
};

const _useMavlinkData = (messageKey, field) => {
  const ws = useWebSocket();

  // Define the schema using yup to ensure the data has field named "event" === "incomingData"
  const eventSchema = yup.object().shape({
    event: yup.string().required().oneOf(["incomingData"]),
    mavlink: yup.lazy((value) =>
      yup.object().shape({
        [messageKey]: yup.object().shape({
          [field]: yup.mixed().required()
        }).required()
      })
    ),
  });

  // Function to validate and extract the data
  const getData = (data) => {
    try {
      const bracketIndex = field.indexOf('[');

      // if index exists, extract the array index by finding the number between brackets
      if (bracketIndex !== -1) {
        const arrayIndex = parseInt(field.substring(bracketIndex + 1, field.length - 1));
        const fieldName = field.substring(0, bracketIndex);
        return data.mavlink[messageKey][fieldName][arrayIndex];
      }


      const validatedData = eventSchema.validateSync(data);
      return validatedData.mavlink[messageKey][field];
    } catch (error) {
      return null;
    }
  };

  // Use the WebSocket data and apply the validation
  const data = ws.data ? getData(ws.data) : null;

  return data;
};

export const useMavlinkData = (sysId, compId, messageType, field, scaler = 1.0) => {
  const messageKey = `mavlink:${sysId}:${compId}:${messageType}`;
  const [prevValue, setPrevValue] = useState(null);
  const rawValue = _useMavlinkData(messageKey, field);
  const currentValue = rawValue !== null ? rawValue * scaler : null;

  useEffect(() => {
    if (currentValue !== null) {
      setPrevValue(currentValue);
    }
  }, [currentValue]);

  return currentValue !== null ? currentValue : prevValue;
}

// be aware of previous value so the value doesn't change when the data is not available
export const useNextVisionData = (key, field) => {
  const messageKey = `payload:NEXTVISION:1:${key}`;
  const [prevValue, setPrevValue] = useState(null);
  const currentValue = _useMavlinkData(messageKey, field);

  useEffect(() => {
    if (currentValue !== null) {
      setPrevValue(currentValue);
    }
  }, [currentValue]);

  return currentValue !== null ? currentValue : prevValue;
}


// be aware of previous value so the value doesn't change when the data is not available
export const useGenericData = (key, field, scaler = 1.0) => {
  const messageKey = `${key}`;
  const [prevValue, setPrevValue] = useState(null);
  const rawValue = _useMavlinkData(messageKey, field);
  const currentValue = (rawValue !== null && typeof rawValue === 'number') ? rawValue * scaler : rawValue;

  useEffect(() => {
    if (currentValue !== null) {
      setPrevValue(currentValue);
    }
  }, [currentValue]);

  return currentValue !== null ? currentValue : prevValue;
}
