import { Icon } from 'leaflet';
import 'leaflet-rotatedmarker';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useState } from 'react';
import { MapContainer, Marker, Polyline, TileLayer, useMap } from 'react-leaflet';
import MissionStatus from '../components/MissionStatus';

import { ACFT_COMP_ID, ACFT_SYS_ID, TRK_COMP_ID, TRK_SYS_ID } from '../Constants';
import { useGenericData, useMavlinkData, useNextVisionData } from '../WebSocketContext';


// https://icons8.com/icon/set/navigation/fluency-systems-filled

const trackerIcon = new Icon({
    iconUrl: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAEBUlEQVR4nO2ca6hlcxjGfzPMMIzLuN/HyBCZkWuSpGY+Yj6JIqaIQhRRFNEQgyJEHUVRbkWG3MZlmm/zkS/GSIZch8mMy7iN8WjVf9Vx2s45e++19vv+/+v91fPl7NM5z/O87zp19l7rD0EQBEEQBEEQBEEQBEHgGMEiwSrB1qRXBCdY++oEglMEvwo0QdXXTrb2VzSCmYIPe5Rfq3ptprXPYhFcNEn5tS609lkkgp0EH01jABuq77X2WxyCS6ZRfq2Lrf2WuP0f9zGATwQ7W/suBsHyPsqvdZm175K2f8MAA/hUMMvaf/YIrhig/FqXW/vPGsEswWdDDOBzwWzrHNkiuGqI8mtdaZ0jSwSzBRsbGMAXgl2s82SH4JoGyq91tXWerBDsKviywQF8I5hjnSsbBNc3WH6t66xz5bT9X7UwgG8Fu1nnc4/gxhbKr3WDdT7XCHYXfNfiAH4QzLXO6RbBzS2WX+sm65yet3/TCAZQXQV7WOd1h+DWEZRf6xbrvK4QzBV8P8IBbBbsaZ3bDYLbR1h+rdusc7tAsJfgR4MBVPcTzaPrCO40KL/WHXQZwd6CLYYD+EmwD11FcLdh+bXuoosI9hX87GAAvwj2p2sI7nVQfq176BKC/Zxsv8bd2HsAXUHwgIPSNUH30wUEBwm2OShcE/S74BBKR/CQg7L1P3qQkhEcLPjNQdGa5Co4jFIRPOqgZE2hRygRweGCPxwUrCn0p2A+pSF43EG5mqYeoyQER6TNUib6S7CAUhA84aBU9akxSkBwZGbbr3FXwVHkjuApB2VqQD1JzgiOFmx3UKQG1N+CY8kVwTMOStSQepocERyT+fZr3FVwHLkheM5BeWpIz5ITguMFOxwUp4ZUZVlELghedFCaGtYL5EB1bk9h26+kfwQn4h3Byw7KUkt6Cc8ITkqbYl2UWrwKTsMrglcdlKSWtQrHR4mVvP0ap9PxhuANB8VoRHodTwhO7dD2K+kMvCB420EhGrHewgOCMx2UISOd7WEA7zkoQkZ617r8sxyUIGOdYzmAtYbB1wtWJq039LHWqvwlIw66XbAmHWWwsIefhem1NQafQywp9R3PbYLXqlOvBAf24W2e4ILq06wRPQL1fLtt9w65uaUwGwUPC5Y2cd5bOnlrafqZTZzA1Uubmmm1v2BNPWCxQ7AuPSm/eAS+F6ffta7Bt823tO27V5D3hzBc3SH9TjqY6dCRm//vkzqXpj+nwyzUagvzywY4NmxMcJ7H48MEc5K3seS1n2znWpm+bwpjHwhWVO+hC2aQCYIZyfOKlGGyjCutzS5L/w9sTUeNvSm4tqRbvAXzU6Yq29fpAe8q8/nW3oIgCIIgCIIgCIIgCIIgCIIgwD3/AqGdT+rZktWEAAAAAElFTkSuQmCC",
    iconSize: [32, 32], // size of the icon
    iconAnchor: [16, 16], // point of the icon which will correspond to marker's location
    popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
})

const aircraftIcon = new Icon({
    iconUrl: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAFKElEQVR4nO2daWhcVRTHb62tVute96UqVTHYJO+eSQxFrKAfXT4FBUUFRUFFQVFQ0CoqWrVobcjknJk2UMEloLjgvpFv/Wi/qBWxWrsYk75zZpK61fjkxsk0WsNkmXnnvTf3BwfCZJj8z/9/7pvJZO6NMR6Px+PxeDwej8fj8Xg8Hk+CAQxXWpK3gERcWZQ3c8W9F2nragpsQQBQxoAk+le525Cttr5ssyY6BFC2HmT+gRC2uvtoy8wsOZTrpjW/UkFBrtXWmUm6B6KFgPxlrQAs8jZ3X229mSMolG6oZX41BJLrtfVmiu6BaKFF/nqmAQDxN6vXRIdq684MlvjmmZs/+YQc3qStO0vTv23WARB/Cxgt0tafegD51tmbXw3hFm39qQYwWmRRvptzACjftwxEi7X7SC2Acvvcp7/yiqggt2n3kUpaBqLFQLJ9vgEAyQ8r1keHafeTOizJnXUwv/LLmdyh3U+qWN0fHW5RfqxXAEC8u2vdjiXafaUGi+E99TN/8gm5dLd2X6mZfiDZWe8ALPEewF1HaPeXeAD5vrpP/4FL0b3a/SWa1s17jrTIPzUwgOGWnqGl2n0mFkB+oHHmVy9F92v3mdjpB+KhRgfgVsGq4vBR2v0mDkvyUOPNr6wClAe1+00ULT1DSwH559gCIB7pXD9ytHbficGiPBKX+dVCeVi770QAGB4DJGHsAZDIyl45zjQ7QPyYgvmVVcCPmmamrZ+PBRTWCsCSlLoKcrxpVgD5SbXpr4bAT5hmpGND6QSLUtYOAEhG2zeWTzTNhkV5OgHmV4qfMs0EYHlZQqY/miiUsdbe0ZNMswAkz6mbTgfVs6YZ6OgZOwVI9iXA8GhqWeRfg+LwaSbrAMkL2mbD9Jei502WARw71ZL8om40Tb8KOmnkDJNVALlH22SoFQLxBpNFcpv2ngnEv2kbDDUvQ/x7UAyXm6xhkfPq5tJMi3tNlgAMz3KTlaIA/ugkPsdkBSAp6Jsqs7wUCZks0Jbns9M1/VJdBbmNcq5JO4DSr2+mzHUVbDJpJijICku8X91ImltZ5D9zxdIFJq0A8kvaJsJ8QyDebNJIB5XOT/P0w5RVEBTKF5q0YYlf0TYP6hfCyyZNtOfLLUA8rm0c1K143J3QYtICkAzomyb1LeTXTBpw5/Zka/plMoC/2vNhm0k6gPKGulnUqBDkdZNkckUO3KSoG0WNWwU5lA6TVADlbXWTqLHljkozyT1KLMPTT1OqIJ0maVji99SNoZhWAfK7JkkEfZJrmumnSghU6jJJAYg/1DYE4i7kD0wSyGFplboZpFNBX3hpEl75fKptBOjVJ8rmh5ckwIRItQp8mV4AJIN6zfNXlmStK/e1YgiDKuYHfXx5nI1a4v0W5XN3lEF7vnTef/W429z33H3i/juE8yKr73juA+R33KlXFxdGT56pNrf5DlC63V+zYtkChfyqiRu317ZBDW23yC/mUK6ox3lv7jHcY7nHrNMJXP9TPGTipn4bLHgciLe4nfJBIWxttG73M/7Zlc9b6va2OQqbuAGSz+Yq2H1C2pJ87A5masuPnB67+MkesLwMKLzRXU7nM1AW+aP4xVN4zSwnfbf7tFnQF16VxOPDutbtWOK0OY0TWmc1UKUrVUQDyjM1xH1hkR+feA89ihaYtBBFC5xmp931UGM1r1XVWlkJg5V/J7ITiN8HlLuy9BHvoBgudz1VetvlNni7nnMYXq2tzePxeDwej8fj8Xg8Ho/H4/F4PB6PSTx/A3ISsEhwfX34AAAAAElFTkSuQmCC",
    iconSize: [32, 32], // size of the icon
    iconAnchor: [16, 16], // point of the icon which will correspond to marker's location
    popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
})

const targetIcon = new Icon({
    iconUrl: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADcklEQVR4nO1Zz08TQRQeOKqJeFITPapIAu28bwoxURHjD5BoPGr0TjCE+F+gHJCrJ1T0AujFf8GLit79ETgAMRjZma2okSA1s62xHXa7293ZYk2/5F3azpvvzcx7870pY038p5DAVQl8lsCqI8QV1mhQwIoCCiVbZo0G9Ze8Z6zRoJoB1Bmqs3Ofy/lNBUxKohfmDpQ+m1REN2Qm08b+Fbicn5DAtCT6YZIONKLvkuiRC/TsGPF14KAmIYGtyMR9TALP14Q4XFfyDueXFZGbhLhhygUG60JeEt2WRJu+q0n0TgJjSohzeaB9BdilLc/5cQWcV0R3JPA+YOymC4ymTj5g8peOEL2R/QB9kuiVn6/UgtDHxlx5CfxUwFCBsZZa/ekxChiWwIbPTgxaT1jzzEuiNYfz00l9O0L0al9mTqzncgfssC9u+RNj5Tf0MbDl3wFOlnazvNQ+tFnnzVI5xCxDEd0yFknP2Z3YsQQemwkb58yHocBYiwReGwv1ILE80LdmudNaqk2tkERnjQC+OcDe2A5L2qaizrOUIYEPRhDXYzvzxFfluRyLMu5Ld/chRTSriPKeAc/cXO5opDmJ7hoB3IsdgI+qvBCFvE9Z9Mqu/i5CABdNFRuJrAtcUsBiNc2i5UEEArNVfMyEjc8D7SG6adEVYmD7xMBymOj61NW1O0IA+Soy2g0bv9rRsSdU/BEt+U28lHoAgEotAFeIAUW0UG2gK8Sx0ACAp1Umngsbny8q12rkFxTn/SytJNbVJiiJ3Wz2SOgCcN4fK4mtl1FgxhOARZuLQt63jBJNsLjQDfiOX2RCXIvvLJNpM6WEBM5YZVxdSqwnkhKeU2Da2NK3BcZamWUUGGtVwLwRwFRix/rpw0dODzPLUMCIsdNbSoicFefmLlhvaIhObWtobKz+H+j2Tl9CZlm0kQ8S6FOAY5CXX3t69jOb0I22T1O/oTupmE29PvMjvk29n9axFMRowA05rytIVD/63UgBbwJ8jaRCvjyIwIct4KN3EXHen89mOyoetooyedz7jd9Y8nymS74siEEzJxKaTO3YhCT2fQn8iktcAlv6gdh6wtYC/fSh33B0A14Def3bKWt13gb0la8bcN3DBv7BQTShtU1ieVAPmAGwRoNqBrDDqHjhIFpgjQa32GMveRa1h22CNR5+A/W7IynqSbg+AAAAAElFTkSuQmCC",
    iconSize: [32, 32], // size of the icon
    iconAnchor: [16, 16], // point of the icon which will correspond to marker's location
    popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
})

function ChangeView({ center }) {
    const map = useMap();
    map.setView(center, map.getZoom());
    return null;
}
function findIntersection(lat1, lon1, lat2, lon2, lat3, lon3, lat4, lon4) {
    // Calculate the slopes (m1 and m2) and intercepts (c1 and c2) for each line
    const m1 = (lon3 - lon1) / (lat3 - lat1);
    const c1 = lon1 - m1 * lat1;

    const m2 = (lon4 - lon2) / (lat4 - lat2);
    const c2 = lon2 - m2 * lat2;

    // Check if lines are parallel (they would have the same slope)
    if (m1 === m2) {
        return null; // No intersection (lines are parallel)
    }

    // Find the intersection point
    const lat = (c2 - c1) / (m1 - m2);
    const lon = m1 * lat + c1;

    return { lat, lon };
}


const MapComponent = () => {

    const [trackerPosition, setTrackerPosition] = useState([0.0, 0.0]); // set the initial position
    const [trackerHeading, setTrackerHeading] = useState(1); // set the initial heading

    const [aircraftPosition, setAircraftPosition] = useState([0.0, 0.0]); // set the initial position
    const [aircraftHeading, setAircraftHeading] = useState(2); // set the initial heading

    const polyline = [trackerPosition, aircraftPosition];

    const [upperLeft, setUpperLeft] = useState([0.0, 0.0]);
    const [upperRight, setUpperRight] = useState([0.0, 0.0]);
    const [lowerRight, setLowerRight] = useState([0.0, 0.0]);
    const [lowerLeft, setLowerLeft] = useState([0.0, 0.0]);

    const [cameraViewCenter, setCameraViewCenter] = useState([0.0, 0.0]);

    const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;

    // cross ref. nextvision klv vs. MISB 0601 std, 
    // point 1 - upper left
    // point 2 - upper right
    // point 3 - lower right
    // point 4 - lower left
    const cameraView = [upperLeft, upperRight, lowerRight, lowerLeft, upperLeft];

    const missionData = useGenericData(`vehicle:${ACFT_SYS_ID}:MISSION_STATUS`, "mission");
    // console.log(missionData);


    const aircraftLat = useMavlinkData(ACFT_SYS_ID, ACFT_COMP_ID, "GLOBAL_POSITION_INT", "lat", 1 / 1e7);
    const aircraftLon = useMavlinkData(ACFT_SYS_ID, ACFT_COMP_ID, "GLOBAL_POSITION_INT", "lon", 1 / 1e7);
    const aircraftJdg = useMavlinkData(ACFT_SYS_ID, ACFT_COMP_ID, "GLOBAL_POSITION_INT", "hdg", 1 / 100);
    

    const trackDataLat= useMavlinkData(TRK_SYS_ID, TRK_COMP_ID, "GLOBAL_POSITION_INT", "lat", 1 / 1e7);
    const trackerDateLon= useMavlinkData(TRK_SYS_ID, TRK_COMP_ID, "GLOBAL_POSITION_INT", "lon", 1 / 1e7);
    const trackerDateHdg= useMavlinkData(TRK_SYS_ID, TRK_COMP_ID, "GLOBAL_POSITION_INT", "hdg", 1 / 100);

    const losDataLowerLeftLatitude= useNextVisionData("LOS_REPORT", "Lower Left LOS Corner Latitude");
    const losDataLowerLeftLongitude= useNextVisionData("LOS_REPORT", "Lower Left LOS Corner Longitude");
    const losDataLowerRightLatitude= useNextVisionData("LOS_REPORT", "Lower Right LOS Corner Latitude");
    const losDataLowerRightLongitude= useNextVisionData("LOS_REPORT", "Lower Right LOS Corner Longitude");
    const losDataUpperRightLatitude= useNextVisionData("LOS_REPORT", "Upper Right LOS Corner Latitude");
    const losDataUpperRightLongitude= useNextVisionData("LOS_REPORT", "Upper Right LOS Corner Longitude");
    const losDataUpperLeftLatitude= useNextVisionData("LOS_REPORT", "Upper Left LOS Corner Latitude");
    const losDataUpperLeftLongitude= useNextVisionData("LOS_REPORT", "Upper Left LOS Corner Longitude");

    useEffect(() => {
      if (aircraftJdg) {
        setAircraftHeading(aircraftJdg);
      }
      if (aircraftLat && aircraftLon) {
        setAircraftPosition([aircraftLat, aircraftLon]);
      }
    }, [aircraftLat, aircraftLon, aircraftJdg]);

    useEffect(() => { 
        if (trackerDateHdg) {
            setTrackerHeading(trackerDateHdg);
        }
        if (trackDataLat && trackerDateLon) {
            setTrackerPosition([trackDataLat, trackerDateLon]);
        }
     },[trackDataLat, trackerDateLon, trackerDateHdg]);


    useEffect(() => {
        // get nextvision data using websocketcontext
        if (losDataUpperLeftLatitude && losDataUpperLeftLongitude) {
            setUpperLeft([losDataUpperLeftLatitude, losDataUpperLeftLongitude]);
        }
        if (losDataUpperRightLatitude && losDataUpperRightLongitude) {
            setUpperRight([losDataUpperRightLatitude, losDataUpperRightLongitude]);
        }
        if (losDataLowerRightLatitude && losDataLowerRightLongitude) {
            setLowerRight([losDataLowerRightLatitude, losDataLowerRightLongitude]);
        }
        if (losDataLowerLeftLatitude && losDataLowerLeftLongitude) {
            setLowerLeft([losDataLowerLeftLatitude, losDataLowerLeftLongitude]);
        }
        // if upper left lat is 400, set upper left = lower left
        if (losDataUpperLeftLatitude === 400 || losDataUpperLeftLongitude === 400) {
            setUpperLeft([losDataLowerLeftLatitude, losDataLowerLeftLongitude]);
        }
        // if upper right lat is 400, set upper right = lower right
        if (losDataUpperRightLatitude === 400 || losDataUpperRightLongitude === 400) {
            setUpperRight([losDataLowerRightLatitude, losDataLowerRightLongitude]);
        }

    }, [
      losDataLowerLeftLatitude,
      losDataLowerLeftLongitude,
      losDataLowerRightLatitude,
      losDataLowerRightLongitude,
      losDataUpperRightLatitude,
      losDataUpperRightLongitude,
      losDataUpperLeftLatitude,
      losDataUpperLeftLongitude,
    ]);

    
    useEffect(() => {           
        // calcaulte the intersection of four corners of the camera view
        const intersection = findIntersection(upperLeft[0], upperLeft[1], upperRight[0], upperRight[1], lowerRight[0], lowerRight[1], lowerLeft[0], lowerLeft[1]);

        if (intersection) {
            if (intersection.lat && intersection.lon) {
                setCameraViewCenter([intersection.lat, intersection.lon]);
            }
            else {
                setCameraViewCenter([0.0, 0.0]);
            }
        }
        else {
            setCameraViewCenter([0.0, 0.0]);
        }

    },[upperLeft, upperRight, lowerRight, lowerLeft]);


    return (
        <MapContainer center={aircraftPosition} zoom={13} scrollWheelZoom={false} style={{ height: "100%", width: "100%", backgroundColor: "black" }}>
            <MissionStatus />
            <ChangeView center={aircraftPosition} />
            <TileLayer
                // url="http://localhost:8080/styles/basic-preview/512/{z}/{x}/{y}.png"
                url={`https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}@2x.jpg90?access_token=${mapboxToken}`}
                attribution=""
                maxNativeZoom={16}
                minZoom={10}
                maxZoom={16}
                opacity={0.7} // darken the layer by reducing opacity
            />

            {/* have to keep the key for the marker to rotate properly. not sure why */}
            <Marker key={aircraftHeading} position={aircraftPosition} icon={aircraftIcon} rotationAngle={aircraftHeading} rotationOrigin="center center"></Marker>
            <Marker key={trackerHeading} position={trackerPosition} icon={trackerIcon} rotationAngle={trackerHeading} rotationOrigin="center center"></Marker>
            <Polyline pathOptions={{
                color: 'grey', weight: 2
            }} positions={polyline} />
            <Polyline pathOptions={{ color: 'lime', weight: 1, opacity: 0.8 }} positions={cameraView} />

            <Marker position={cameraViewCenter} icon={targetIcon} rotationOrigin="center center"></Marker>

            <Polyline pathOptions={{
                color: 'yellow', weight: 3, opacity: 0.8
            }} positions={
                missionData ? missionData.map(point => [point.lat, point.lon]) : []
            } />

        </MapContainer >
    );
};

export default MapComponent;
